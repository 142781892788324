import React, { useEffect, useRef } from "react";
import { BsCheckLg } from "react-icons/bs";
// import ImgsViewer from 'react-images-viewer';
import Viewer from 'react-viewer';
import { useState } from "react";

import { images } from "../constants";

const ProjectCard = ({ project, className }) => {
  const [isImgOpen, setIsImgOpen] = useState(false);
  const myContainer = useRef(null);

  return (
    <div
      id={`project-card-${project.id}`}
      ref={myContainer}
      className={`rounded-xl p-5 gradient__border shadow-3xl project-card opacity-80 md:hover:opacity-100 overflow-hidden w-11/12 sm:w-full`} 
      // shadow-[rgba(7,_65,_210,_0.1)_0px_9px_30px]  md:w-[calc(50%-20px)] lg:w-[calc(33.33%-21px)]
    >
      <Viewer
        visible={isImgOpen}
        onClose={() => setIsImgOpen(!isImgOpen) }
        onMaskClick={() => setIsImgOpen(!isImgOpen)}
        images={project.images.length > 1 ? project.images.map(img => ({ src: img, alt: 'image' })) : [{src: project.images[0], alt: 'image'}]}
        showTotal={false}
        defaultImg
        rotatable={false}
        scalable={false}
        changeable={project.images.length > 1 ? true : false}
        noImgDetails
        noNavbar={true}
        zoomSpeed={0.3}
        attribute={false}
        downloadable={false}
        className=""
      />
      {/* <ImgsViewer
          imgs={[
            { src: project.image },
          ]}
          isOpen={isImgOpen}
          // onClickPrev={this.gotoPrevImg}
          // onClickNext={this.gotoNextImg}
          onClose={() => setIsImgOpen(!isImgOpen)}
          backdropCloseable
          showImgCount={false}
        /> */}
      <div className="p-4">
        {project.images ? 
          <img
            src={project.images[0]}
            alt="title"
            className="w-full object-contain object-center h-52 lg:h-48 xl:h-48 cursor-pointer bg-black"
            onClick={() => setIsImgOpen(!isImgOpen)}
          />
        : 
        <img
          src={images.Post1Image}
          alt="title"
          className="w-full object-contain object-center h-52 lg:h-48 xl:h-60"
        />}
      </div>
      <div className="px-8">
        <h2 className="font-roboto font-bold text-xl text-white md:text-[20px]">
          {project.title}
        </h2>
        <ul className="text-white mt-2 text-sm md:text-base"> {/*list-disc*/}
          {project.description.map((desc, i) => (
            <li key={i}>{desc}</li>
          ))}
        </ul>
        <div className="flex justify-between flex-nowrap items-center mt-4">
          <div className="flex items-center gap-x-2 gap-y-2 md:gap-x-2.5 flex-wrap">
            {project.skills.map((skill, i) => (
              <div key={i} className="inline-block rounded-md px-3 py-1.5 gradient__tag font-roboto text-xs text-white md:text-sm">
                {skill}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;
