import Logo from "../assets/logo.svg";
import ThreedPoseArchitect from "../assets/projects/threedArchitect.png";
import ThreedPose from "../assets/projects/threedPost.png";
import SunPoOrder from "../assets/projects/SunPoOrder.jpg";
import GoalMateHome from "../assets/projects/GoalMateHome_1284x2778.jpg";
import LetFitness from "../assets/projects/letsfitness.png";
import Programming from "../assets/Programming.jfif";
import ComputerPic from "../assets/ComputerPic.svg";
import hkulogo from "../assets/hkulogo.png";
import wizlogo from "../assets/wizlogo.png";
import Code from "../assets/hero/codeBlur.png";
import Code32 from "../assets/favicon-code-32.png";
import codeMenu from "../assets/codeMenu.png";
import moneyPigTimeline from "../assets/projects/timeline_1284x2778.jpg";
import moneyPigDashboard from "../assets/projects/dashboard_1284x2778.jpg";
import moneyPigCategory from "../assets/projects/category_1284x2778.jpg";
import moneyPigWallet from "../assets/projects/wallet_1284x2778.jpg";
import moneyPigTransaction from "../assets/projects/transaction_1284x2778.jpg";

const images = {
  Logo,
  ThreedPoseArchitect,
  ThreedPose,
  SunPoOrder,
  GoalMateHome,
  LetFitness,
  Programming,
  ComputerPic,
  hkulogo,
  wizlogo,
  Code,
  Code32,
  codeMenu,
  moneyPigTimeline,
  moneyPigDashboard,
  moneyPigCategory,
  moneyPigWallet,
  moneyPigTransaction
};

export default images;
