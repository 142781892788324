import React from "react";
import { FiSearch } from "react-icons/fi";

import { images } from "../../../constants";


const introDetails = {
  info: "Hi, I'm Leon Chan",
  desc: "I specialize in developing web and mobile applications, and machine learning models.",
  // desc: "I'm a passionate software developer with 3+ years of experience in developing robust and scalable web applications. Proficient in a wide array of technologies, including React.js, Vue.js, Node.js, ASP.NET, and Python, with a deep understanding of both front-end and back-end technologies. Skilled in working with databases and proficient in writing efficient SQL queries for seamless data retrieval and manipulation. Eager to leverage my skills and expertise in the tech industry in Canada.",
  // desc: "I'm a skilled software developer with experience in TypeScript and JavaScript, and expertise in frameworks like React, Node.js, and Three.js. I'm a quick learner and collaborate closely with clients to create efficient, scalable, and user-friendly solutions that solve real-world problems. Let's work together to bring your ideas to life!"
  skills: ["React", "React Native", "Vue.js", "Node", "ASP.NET", "SQL Server", "MongoDB", "NoSQL", "Elasticsearch", "Python", "NLP", "AWS", "Azure", 
          "Heroku", "Docker", "Kubernetes", "Terraform", "Git", "Mocha"],
  roles: ["Web Developer", "Data Scientist"],
  role: "Software Engineer"
}

const Hero = () => {
  return (
    <div className="container mx-auto flex flex-col px-5 my-16 sm:my-32 md:mt-48 md:mb-80 lg:flex-row">
      <div className="flex flex-col justify-center mt-10 lg:w-1/2">
        <p className="font-roboto text-3xl text-center font-bold gradient__text md:text-4xl lg:text-4xl xl:text-5xl lg:text-left lg:max-w-[540px]">
          {introDetails.info}
        </p>
        <p className="font-roboto text-3xl py-4 text-center font-bold text-white md:text-2xl lg:text-3xl xl:text-3xl lg:text-left lg:max-w-[540px]">
          {introDetails.role}
        </p>
        <p className="text-slate-400 text-center md:text-xl lg:text-base xl:text-xl lg:text-left">
          {introDetails.desc}
        </p>
        <div className="flex mt-4 flex-col lg:flex-row lg:items-start lg:flex-nowrap lg:gap-x-4 lg:mt-7">
          {/* <span className="text-slate-400 font-semibold italic mt-2 lg:mt-4 lg:text-sm xl:text-base">
            Skills
          </span> */}
          <ul className="flex flex-wrap gap-x-2.5 gap-y-2.5 mt-3 lg:text-sm xl:text-base">
            {introDetails.skills?.map((skill, index) => (
              <li key={index} className="rounded-lg bg-primary bg-opacity-10 px-3 py-1.5 text-primary font-semibold">
                {skill}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="hidden lg:block lg:1/2 ml-14">
        <img
          className="w-full max-h-[44rem]"
          src={images.Code}
          alt="users are reading articles"
        />
        {/* <video className="w-full max-h-[44rem]" src={images.Code} autoPlay loop muted /> */}
      </div>
    </div>
  );
};

export default Hero;
