import React from "react";

const Footer = () => {
  return (
    <section className="">
      <footer className="container mx-auto px-5 py-10 gap-y-10 gap-x-5 md:pt-5 lg:gap-x-10 text-xs md:text-base">
        <div className="flex flex-col items-center space-y-4">
          <p className="font-bold italic text-dark-light">
            Copyright © 2023 Leon Chan. All Rights Reserved.
          </p>
        </div>
      </footer>
    </section>
  );
};

export default Footer;
