import React from "react";
import { FaArrowRight } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

import ProjectCard from "../../../components/ProjectCard";
import { images } from "../../../constants";

const experiences = [
  {
    id: 1,
    title: "Software Engineer, Data Science",
    company_name: "Wizpresso",
    icon: images.wizlogo,
    iconBg: "#ffffff",
    date: "Aug 2021 - May 2023",
    points: [
      "Developed Sentiment Analysis tools to automatically identify the polarity of sentences and spot out positive and negative statements, enabling efficient analysis of filings without the need to read through all the text.",
      "Revamped an advanced PDF extraction tool to extract text and tables from over 50 million unstructured financial documents and utilized the extracted data as input for internal machine learning models for generating financial analysis and insights.",
      "Created a Text Classification model with an 85% F1-Score to classify the business nature of financial sentences, enabling easy screening of relevant sentences within financial filings, including ESG data and company financial statements.",
      "Assisted in building an OCR model to extract text from scanned images or documents, streamlining the process speed by 70%, enabling our company to rapidly label document data as training data of machine learning models.",
    ],
  },
  {
    id: 2,
    title: "Full Stack Software Engineer",
    company_name: "Wizpresso",
    icon: images.wizlogo,
    iconBg: "#ffffff",
    date: "April 2020 - Aug 2021",
    points: [
      "Co-developed an integrated verification web application for Initial Public Offering (IPO) documents, enabling the legal practitioners in the IPO markets to accelerate the business processes, streamline paper-based and manual work, and save up to 75% time in the due diligence process.",
      "Overhauled a financial thematic search application, enabling app users to search company profiles and filings across from 28000+ listed companies worldwide using keywords and 1300+ metadata parameters.",
      "Designed and implemented a real-time notification system that delivers market trends and investment opportunities to users via email and in-app messaging, resulting in a 25% increase in user engagement and satisfaction.",
      "Developed an html highlighter tool that allows user to highlight text or PDF areas and create tags in a document reader, enabling efficient bookmarking and retrieval of important information within a PDF or HTML document.",
    ],
  },
  {
    id: 3,
    title: "Master of Computer Science",
    company_name: "The University of Hong Kong (HKU)",
    icon: images.hkulogo,
    iconBg: "#ffffff",
    date: "Sept 2020 - Aug 2021",
    points: [
      "Selected Coursework: Cluster and Cloud Computing, Machine Learning, Computer Vision, Deep Learning",
    ],
  },
  {
    id: 4,
    title: "BBA(IS) / BENG(CS)",
    company_name: "The University of Hong Kong (HKU)",
    icon: images.hkulogo,
    iconBg: "#ffffff",
    date: "Sept 2016 - Aug 2020",
    points: [
      "Selected Coursework: Data Structures and Algorithms, Web Development, Operating System, OOP",
    ],
  },
];

const Experience = () => {

  return (
    <div className="flex flex-col container mx-auto px-5 py-20 mt-24 sm:mt-48 md:mt-56" id="experience">
      <div className="flex flex-wrap md:gap-x-5 gap-y-5 sm:pb-5 justify-center">
        <div className="m-3 p-3 sans-serif font-bold text-2xl text-white md:text-3xl lg:text-[48px]">My Experience</div>
      </div>
      <VerticalTimeline>
        {experiences.map((experience) => (
          <VerticalTimelineElement
            key={experience.id}
            contentStyle={{
              background: "#1d1836",
              color: "#fff",
            }}
            contentArrowStyle={{ borderRight: "7px solid  #232631" }}
            date={experience.date}
            iconStyle={{ background: experience.iconBg }}
            icon={
              <div className='flex justify-center items-center w-full h-full'>
                <img
                  src={experience.icon}
                  alt={experience.company_name}
                  className='w-[60%] h-[60%] object-contain'
                />
              </div>
            }
          >
            <div>
              <h3 className='text-white text-[24px] font-bold'>{experience.title}</h3>
              <p
                className='text-secondary text-[16px] font-semibold'
                style={{ margin: 0 }}
              >
                {experience.company_name}
              </p>
            </div>
      
            <ul className='mt-5 list-disc ml-5 space-y-2'>
              {experience.points.map((point, index) => (
                <li
                  key={`experience-point-${index}`}
                  className='text-white-100 text-[14px] pl-1 tracking-wider'
                >
                  {point}
                </li>
              ))}
            </ul>
          </VerticalTimelineElement>
        ))}
      </VerticalTimeline>
    </div>
  );
};

export default Experience;
