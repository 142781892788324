import React, { useState, useEffect } from "react";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";

import { images } from "../constants";

const navItemsInfo = [
  { name: "Home", href: "#" },
  // { name: "About", href: "#about" },
  { name: "Experience", href: "#experience" },
  { name: "Project", href: "#project" },
  { name: "Contact", href: "#contact" },
];

const NavItem = ({ item }) => {
  const [dropdown, setDropdown] = useState(false);

  const toggleDropdownHandler = () => {
    setDropdown((curState) => {
      return !curState;
    });
  };

  return (
    <li className="relative group">
        <>
          <a href={item.href} className=" px-4 py-2 text-slate-300 group-hover:bg-slate-700 group-hover:rounded-lg">
            {item.name}
          </a>
          {/* <span className="cursor-pointer text-blue-500 absolute transition-all duration-500 font-bold right-0 top-0 group-hover:right-[90%] opacity-0 group-hover:opacity-100">
            /
          </span> */}
        </>
    </li>
  );
};

const Header = () => {
  const [navIsVisible, setNavIsVisible] = useState(false);
  const [scrollDir, setScrollDir] = useState("scrolling up");

  useEffect(() => {
    const threshold = 40;
    let lastScrollY = window.scrollY;
    let ticking = false;

    const updateScrollDir = () => {
      const scrollY = window.scrollY;
      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false;
        return;
      }
      setScrollDir(scrollY > lastScrollY ? "scrolling down" : "scrolling up");
      lastScrollY = scrollY > 0 ? scrollY : 0;
      ticking = false;
    };

    const onScroll = (e) => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    };

    window.addEventListener("scroll", onScroll);
    // console.log(scrollDir);

    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollDir]);

  const navVisibilityHandler = () => {
    setNavIsVisible((curState) => {
      return !curState;
    });
  };

  return (
    <section className={`sticky top-0 left-0 right-0 z-50 bg-neutral-focus ${scrollDir === "scrolling down" && !navIsVisible && window.screen.width < 1024 ? "opacity-0" : ""}`}>
      <header className={`container mx-auto px-5 flex justify-between py-4 sm:py-6 items-center bg-[#040C18] sm:bg-neutral-focus`}>
        <a href="#">
          <img className="w-14 hidden lg:block cursor-pointer" src={images.Code32} alt="logo" onClick={() => {}}/>
        </a>
        <div className="lg:hidden z-50">
          {navIsVisible ? (
            <AiOutlineClose
              className="w-6 h-6 text-white"
              onClick={navVisibilityHandler}
            />
          ) : (
            <AiOutlineMenu className="w-6 h-6 text-white" onClick={navVisibilityHandler} />
          )}
        </div>
        <div
          className={`${
            navIsVisible ? "right-0" : "-right-full"
          } transition-all duration-300 mt-[56px] lg:mt-0 bg-dark-hard lg:bg-transparent z-[49] flex flex-col w-full lg:w-auto justify-center lg:justify-end lg:flex-row fixed top-0 bottom-0 lg:static gap-x-9 items-center`}
          onClick={() => {setNavIsVisible(false)}}
        >
          <ul className="text-white items-center gap-y-5 lg:text-dark-soft flex flex-col lg:flex-row gap-x-2 font-semibold">
            {navItemsInfo.map((item) => (
              <NavItem key={item.name} item={item}/>
            ))}
          </ul>
        </div>
      </header>
    </section>
  );
};

export default Header;
