import React from "react";

import MainLayout from "../../components/MainLayout";
import Experience from "./container/Experience";
import Contact from "./container/Contact";
import Hero from "./container/Hero";
import ProjectContainer from "./container/ProjectContainer";
import About from "./container/About";
import toast, { Toaster } from 'react-hot-toast';

const HomePage = () => {
  return (
    <MainLayout>
      <Toaster />
      <Hero />
      {/* <About /> */}
      <Experience />
      <ProjectContainer />
      <Contact />
    </MainLayout>
  );
};

export default HomePage;
