import React, { useState, useEffect } from "react";
import { FaArrowRight } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import ReactCardSlider from "../../../components/ReactCardSlider";
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';

import ProjectCard from "../../../components/ProjectCard";
import { images } from "../../../constants";

const projects = [
    { 
      id: 1,
      title: "Money Pig Mobile Application", 
      images: [images.moneyPigTimeline, images.moneyPigDashboard, images.moneyPigCategory, images.moneyPigTransaction, images.moneyPigWallet],
      description: ["A multi-language, cloud-based expense tracking mobile application for iOS and Android, featuring seamless transaction recording, an integrated calendar, advanced data visualization tools, and support for multiple wallets.",],
      skills: ["React Native", "Supabase", "Expo", "iOS", "Android"]
    },
    { 
      id: 2,
      title: "3D Pose Estimation in Football Shooting Technique", 
      images: [images.ThreedPose],
      description: ["A SPA web application that utilizes machine learning algorithms to evaluate human posture and shooting performance for football players.",],
      skills: ["React", "Flask", "YOLO", "Threejs", "OpenCV", "Amplify", "EC2"]
    },
    { 
      id: 3,
      title: "Sun Po POS Sytem",
      images: [images.SunPoOrder],
      description: ["A POS System for a restaurant that streamlines order processing, enhance customer service, and optimize inventory management."],
      skills: ["React", "Expressjs", "MongoDB", "Php", "MySQL", "Javascript"]
    },
    { 
      id: 4,
      title: "Let's Fitness membership system",
      images: [images.LetFitness],
      description: ["A class scheduling system that enabled the company to effectively schedule and manage fitness classes digitally.",],
      skills: ["React", "React Native", "Spring Boot", "Maven", "MySQL"]
    },
    { 
      id: 5,
      title: "GoalMate", 
      description: ["A football matchmaking app that empowers users to create and participate in real-world football matches."],
      images: [images.GoalMateHome],
      skills: ["React Native", "Socket.io", "Expressjs", "NoSQL", "AWS"]
  },
]

const ProjectContainer = () => {
  const [showMore, setShowMore] = useState(false);
  const projectLimit = 5;
  const [scrollDir, setScrollDir] = useState("scrolling up");

  useEffect(() => {
    let ticking = false;

    const updateScrollDir = () => {
      if (window.screen.width < 768) {
        projects.forEach((project) => {
          // debug
          // if (project.id !== 1) return
          var offset = 0
          var bodyRect = document.body.getBoundingClientRect()
          var projectId = 'project-card-' + project.id.toString()
          var element = document.getElementById(projectId)
          if (element) {
            var rect = element.getBoundingClientRect();
            var offset = rect.top - bodyRect.top;
            var cardHeight = rect.bottom - rect.top
            // console.log(rect.top, rect.bottom, offset, rect.top, window.scrollY);
            if ((window.scrollY > (offset - (cardHeight/2))) && (window.scrollY <= (offset + cardHeight/2))) {
              element.style.boxShadow = "0 4px 10px #0C3D89"
            } else {
              element.style.boxShadow = ""
            }
          }
          ticking = false;
        });
      }
    };

    const onScroll = (e) => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    };

    const onMouseMove = (e) => {
      // only one card has boxShadow
      if (window.screen.width < 768) {
        resizeBoxShadow()
      }
    };

    const resizeBoxShadow = () => {
      projects.forEach((project) => {
        var projectId = 'project-card-' + project.id.toString()
        var element = document.getElementById(projectId)
        if (element) {
          // if (element.matches(':hover')) {
            element.style.boxShadow = ""
          // }
        }
      });
    }

    const onResize = (e) => {
      // reset boxShadow
      // console.log(window.screen.width)
      resizeBoxShadow()
      window.requestAnimationFrame(updateScrollDir);
    };

    window.addEventListener("scroll", onScroll);
    window.addEventListener("mousemove", onMouseMove);
    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("scroll", onScroll)
      window.removeEventListener("mousemove", onMouseMove)
      window.removeEventListener("resize", onResize)
    };
  }, []);
  
  const slideLeft =()=>{
    var slider = document.getElementById("slider");
    slider.scrollLeft = slider.scrollLeft - 500;
  }

  const slideRight =()=>{
    var slider = document.getElementById("slider");
    slider.scrollLeft = slider.scrollLeft + 500;
  }

  return (
    <div id='project' className="flex flex-col container mx-auto px-5 mb-16 md:my-48 bg-[#040C18] rounded-lg">
      <div className="flex flex-wrap md:gap-x-5 gap-y-5 pt-16 md:py-10 justify-center">
        <div className="m-3 p-3 sans-serif font-bold text-xl text-white md:text-3xl lg:text-[48px]">Featured Projects</div>
      </div>
      <div className="md:gap-x-7 gap-y-7 lg:mx-20 grid md:grid-cols-2 2xl:grid-cols-3 justify-items-center">
        {/* <MdChevronLeft size={40} className="slider-icon left" onClick={slideLeft}/> */}
        {projects.map((project, index) => (
          (index < projectLimit) | showMore ? 
            <ProjectCard key={project.id} project={project} />
            : <></>
        ))}
        {/* <ReactCardSlider slides={projects} /> */}
      </div>
      {projects.length > projectLimit ? 
      <button className="mx-auto flex items-center gap-x-2 font-bold text-primary border-2 border-primary px-6 py-3 rounded-lg" onClick={() => {setShowMore(!showMore)}}>
        {showMore ? 
        <span>Show Less</span>
        :
        <span>Show More</span>
        }
      </button>  
      :
      <></>
      }
    </div>
  );
};

export default ProjectContainer;
