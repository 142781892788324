import { useEffect, useState } from "react";
import {
  AiOutlineTwitter,
  AiFillYoutube,
  AiFillInstagram,
  AiFillHeart,
  AiFillLinkedin,
  AiOutlineGithub,
  AiFillProfile
} from "react-icons/ai";
import { FaFacebook } from "react-icons/fa";
import { BsTelegram } from "react-icons/bs";
import { MdEmail, MdAccountBox } from "react-icons/md";
import emailjs from 'emailjs-com';
import { toast } from "react-hot-toast";

import { images } from "../../../constants";

const Contact = () => {
  const [getInTouchClass, setGetInTouchClass] = useState(false);

  useEffect(() => {
    changeGetInTouchClass();
  }, []);

  window.addEventListener("resize", function(event) {
    changeGetInTouchClass(event)
  })

  function changeGetInTouchClass(event) {
    // console.log(window.screen.orientation.angle, window.screen.orientation.type)
    if (window.screen.width >= 768) {
      setGetInTouchClass(true)
    } else {
      setGetInTouchClass(false)
    }
  }

  const sendEmail = (e) => {
    e.preventDefault()
    emailjs.sendForm(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLATE_ID, e.target, process.env.REACT_APP_EMAILJS_USERID)
      .then((result) => {
          // window.location.reload()  //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior) 
          toast.success("Message is sent successfully.");
          var frm = document.getElementsByName('contact-form')[0];
          frm.reset();  // Reset all form data
      }, (error) => {
          console.log(error.text);
          toast.error("Error in sending message.");
      });
  }

  return (
    <div id="contact" className='pt-2 py-10 my-8 flex justify-items-stretch'>
      {/* https://tailwindui.com/components/marketing/sections/contact-sections */}
      <div className="grid grid-rows-2 md:grid-rows-none md:grid-cols-2 grid-flow-col w-50 mx-auto">
        {/* <div className="flex isolate bg-[#000000] px-6 py-24 sm:py-32 lg:px-8 rounded-lg" style={{backgroundImage: "linear-gradient(90deg, #000000 0%, #2c3e50 74%)"}}> */}
        <div className={`${getInTouchClass ? "rounded-bl-3xl" : "rounded-tr-3xl"} flex isolate px-8 bg-[#rgb(44,62,80)] rounded-tl-3xl`} style={{backgroundImage: "linear-gradient(100deg, rgba(44,62,80,1) 0%, rgba(31,34,41,1) 96%)"}}>
        {/* <div className="flex isolate bg-[#rgb(44,62,80)] px-6 py-24 sm:py-32 lg:px-8" style={{backgroundImage: "linear-gradient(90deg, rgba(44,62,80,1) 0%, rgba(31,34,41,1) 64%)"}}> */}
          {/* <div className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]" aria-hidden="true">
            <div className="relative left-1/2 -z-10 aspect-[1155/678] w-[36.125rem] max-w-none -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-40rem)] sm:w-[72.1875rem]" 
            style={{clipPath: "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)"}}>
            </div>
          </div> */}
          <div className="mx-auto max-w-xl text-center self-center">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">GET IN TOUCH</h2>
            {/* <p className="mt-4 text-lg leading-8 text-gray-400">I'm excited to connect with you! Whether you have questions about my work, want to discuss a potential collaboration, or simply want to say hello, don't hesitate to reach out. Feel free to use the contact form below or connect with me through my social profiles.</p>
            <p className="mt-4 text-lg leading-8 text-gray-400">Feel free to use the contact form or connect with me through my social profiles.</p> */}
            <p className="mt-4 text-lg leading-8 text-gray-400">Feel free to reach out for inquiries, collaborations, or just to say hello. I welcome all opportunities to connect. Let's start a conversation today.</p>
            <ul className="flex justify-center items-center mt-5 space-x-4 text-gray-400">
              <li>
                <a href="mailto:chchunleon@gmail.com" rel="noreferrer">
                  <MdEmail className="w-6 h-auto cursor-pointer hover:text-gray-200" title="chchunleon@gmail.com"/>
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/in/chchunleon" target="_blank" rel="noreferrer">
                  <AiFillLinkedin className="w-6 h-auto cursor-pointer hover:text-gray-200" title="https://www.linkedin.com/in/chchunleon"/>
                </a>
              </li>
              <li title="Visit Example Website">
                <a href="https://github.com/chchunleon" target="_blank" rel="noreferrer">
                  <AiOutlineGithub className="w-6 h-auto cursor-pointer hover:text-gray-200" title="https://github.com/chchunleon"/>
                </a>
              </li>
              {/* <li>
                <a >
                  <MdAccountBox className="w-6 h-auto cursor-pointer" />
                </a>
              </li> */}
            </ul>
          </div>
        </div>
        {/* <div className="isolate bg-[#E0040C18] px-6 py-24 sm:py-32 lg:px-8 rounded-lg" style={{backgroundImage: "linear-gradient(90deg, #000000 0%, #2c3e50 74%)"}}> */}
        <div className={`${getInTouchClass ? "rounded-tr-3xl" : "rounded-bl-3xl"} isolate bg-[#1f2229] px-12 py-16 sm:py-16 lg:px-4 rounded-br-3xl`}>
            <form action="#" method="POST" className="mx-auto max-w-md" name="contact-form" onSubmit={sendEmail}>
              <div className="grid grid-cols-1 gap-x-8 gap-y-6 md:grid-cols-2">
                <div>
                  <label htmlFor="first-name" className="block text-sm font-semibold leading-6 text-white">First name</label>
                  <div className="mt-2.5">
                    <input type="text" name="first-name" id="first-name" placeholder="First Name" autoComplete="given-name" className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                  </div>
                </div>
                <div>
                  <label htmlFor="last-name" className="block text-sm font-semibold leading-6 text-white">Last name</label>
                  <div className="mt-2.5">
                    <input type="text" name="last-name" id="last-name" placeholder="Last Name" autoComplete="family-name" className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label htmlFor="email" className="block text-sm font-semibold leading-6 text-white">Email</label>
                  <div className="mt-2.5">
                    <input type="email" name="email" id="email" placeholder="Email" autoComplete="email" className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label htmlFor="subject" className="block text-sm font-semibold leading-6 text-white">Subject</label>
                  <div className="mt-2.5">
                    <input type="text" name="subject" id="subject" placeholder="Subject" autoComplete="on" className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label htmlFor="message" className="block text-sm font-semibold leading-6 text-white">Message</label>
                  <div className="mt-2.5">
                    <textarea name="message" id="message" rows="4" placeholder="Write your message here..." className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></textarea>
                  </div>
                </div>
              </div>
              <div className="mt-10">
                <button type="submit" 
                  className="block w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Send
                </button>
              </div>
            </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
